import React from "react";
import './style.css';

interface Tab {
  label: string;
  value: string;
  qty?: number;
}

interface ToggleTabsProps {
  tabs: Tab[];
  selectedTab: string;
  setSelectedTab: (value: string) => void;
}

export const ToggleTabs: React.FC<ToggleTabsProps> = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className="tab-container">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={`btn tab-button ${selectedTab === tab.value ? "btn-primary" : "btn-outline-primary"} mx-2`}
          onClick={() => setSelectedTab(tab.value)}
        >
          {tab.label}
          {tab.qty !== undefined && (
            <span className="notification-badge">
              {tab.qty}
            </span>
          )}
        </button>
      ))}
    </div>
  );
};
