import { createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import { api } from '../../config';
import {  INotificationUpdate, IOrderUpdate, IProduct, IProductAction, ITableResponse, ProductActionEnum } from '../../components';
import { startLoading, stopLoading } from './loadingSlice';

export interface ITableViewDataState {
  tables?: ITableResponse;
  recipt: IProduct[];
}

const initialState: ITableViewDataState = {
  tables: {},
  recipt: []
};



export const tableViewDataAsync = createAsyncThunk(
  'table/view',
  async ({ id }: { id?: number }, thunkAPI) => {

    try{
      thunkAPI.dispatch(startLoading());
      const url = id ? `/api/table/find/${id}` : `/api/table/find`;
      const response = await api.get(url);
     return await response.data;
    
    }catch(e){
    }finally{
      thunkAPI.dispatch(stopLoading());

    }
  }
);


export const tableUpdateAsync = createAsyncThunk(
  'table/update',
  async ({
    tableId,
    positionX,
    positionY,
  }: {
    tableId: number;
    positionX?: number;
    positionY?: number;
  }) => {
    const response = await api.put(`/api/table/update-table/${tableId}`, {
      positionX,
      positionY,
    });
    const data: ITableResponse = await response.data;
    return data;
  }
);

export const tableViewDataSlice = createSlice({
  name: 'getAllProducts',
  initialState,
  reducers: {
    updateOrders: (state, action: PayloadAction<IOrderUpdate>) => {
      const payload = action.payload;
      const existingTable = state.tables?.tables?.find(table => table.id === payload.tavId);
    
      if (existingTable) {
        existingTable.orders = payload.orders;
      }
    },
    updateNotfitication:(state,action:PayloadAction<INotificationUpdate>)=>{
      const payload = action.payload;
      const existingTable = state.tables?.tables?.find(table => table.id === payload.tavId);
      if (existingTable) {
        existingTable.notifications = payload.notifications;
      }

    },
    addProductToCart: (state, action: PayloadAction<IProduct>) => {
      const newItem = action.payload;

      const existingProductIndex = state.recipt.findIndex(
        (product) => product.id === newItem.id
      );

      if (existingProductIndex !== -1) {
        const existingProduct = state.recipt[existingProductIndex];
        if (existingProduct && existingProduct.quantity) {
          existingProduct.quantity += 1;
        }
      } else {
        state.recipt.push({ ...newItem, quantity: 1 });
      }
    },
    clearCart:(state)=>{
      state.recipt = []
    },
  
    updateTable:(state, action)=>{
      state.tables = action.payload.tables
    },
    modifyProductOnCart: (state, action: PayloadAction<IProductAction>) => {
      const payload = action.payload;
      const existingProductIndex = state.recipt.findIndex(
        (product) => product.id === payload.productId
      );

      const existingProduct = state.recipt[existingProductIndex];
      const filteredItems = state.recipt.filter(product => product.id !== payload.productId)

      if (payload.action === ProductActionEnum.decrease) {
        if (existingProduct && existingProduct.quantity) {
          existingProduct.quantity > 1 ? existingProduct.quantity -= 1 : state.recipt = filteredItems    
        }
      } else if (payload.action === ProductActionEnum.increase) {
        if (existingProduct && existingProduct.quantity) {
          existingProduct.quantity += 1;
        }
      } else if (payload.action === ProductActionEnum.delete) {
        state.recipt = filteredItems
      }

    },
  },

  extraReducers: builder => {
    builder.addCase(
      tableViewDataAsync.fulfilled,
      (state: ITableViewDataState, action) => {
        state.tables = action.payload;
      }
    )
 
  },
});

export default tableViewDataSlice.reducer;
export const { addProductToCart, modifyProductOnCart , clearCart , updateOrders,updateTable ,updateNotfitication} = tableViewDataSlice.actions;

