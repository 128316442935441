import Draggable from 'react-draggable';
import { Chair } from '../chair/Chair';
import { ISingleTable } from './models/ISingleTable';
import { useSingleTable, useNotificationUpdate } from './hooks';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { FaPerson, FaMoneyBillWave } from "react-icons/fa6";
import { waiterNotification } from './helpers';


export const SingleTable = ({ table, isAllowedToDrag }: ISingleTable) => {
  const navigate = useNavigate();
  useNotificationUpdate(Number(table.id))

  const { createArrayBasedOnNumber, handleDrag, tableAviliability, onNotificationClick } =
    useSingleTable({ table });

const newNotifications = useMemo(() => {
  return table?.notifications?.filter(notification => notification.status === 'Created');

}, [table?.notifications]);

  return (
    <Draggable
      defaultPosition={{ x: table.positionx || 0, y: table.positiony || 0 }}
      onStop={(e, data) => handleDrag(e, data, table?.id ?? 1)}
      key={table.id}
      disabled={!isAllowedToDrag}
      onDrag={e => e.stopPropagation()}
    >
      <div
        className={`main-container ${tableAviliability(table?.orders ?? [])}`}
        key={table.id}
        onClick={() => navigate('tavolina', { state: { id: table?.id } })}
      >
        <div className='main-table left-line'>
          <span
            className='order-in-quee'
            role='button'
            onClick={() => alert('Clikuar njoftimi!')}
          >
            {Number(table.name) > 9 ? table.name : `0${table.name}`}
          </span>
          <span className='table-order'>
            {Number(table.name) > 9 ? table.name : `0${table.name}`}{' '}
          </span>
          <div className='table-order-table'>
            <div className='notication'>
              {newNotifications?.map(notification => (
                <div className="pulse" onClick={(e) => onNotificationClick(e, notification.notificationId,table.id)}>
                  <p> {notification.notificationType === 'askForWaiter' ? <FaPerson /> : <FaMoneyBillWave />}</p>
                  <p> {waiterNotification[notification.notificationType]}</p>
                </div>
              ))}
            </div>
          </div>
          {createArrayBasedOnNumber(Number(table.chair)).map((item, idx) => (
            <Chair key={idx} index={idx} />
          ))}
        </div>
      </div>
    </Draggable>
  );
};
