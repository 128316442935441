import './header.css';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../store';
import { IMyReport } from '../table/models/TableResponse';


export const Header = () => {
  const myReport = useSelector<IStoreState, IMyReport| undefined>(
    state => state.tablesView?.tables?.myReport
  );


  return (
    <Navbar bg="light" expand="lg">
      <Container className='custom-nav-container'>
        <Navbar.Brand as={Link} to="/">
         Logo
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/services">
              Services
            </Nav.Link>
          </Nav>

          <Nav>
            {myReport?.dailyEarn && (
              <p className='nav-link'>
                Totali: {Number(myReport.dailyEarn)} L
              </p>
            )}

            <NavDropdown title="More" id="basic-nav-dropdown" align="end">
              <NavDropdown.Item as={Link} to="/profili">
                Profili 
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/faq">
                FAQ
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/settings">
                Cilesimet
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};