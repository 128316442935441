import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IStoreState } from '../../store';
import {
  ITableViewDataState,
  tableViewDataAsync,
} from '../../store/slices/tableSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './hooks/useAppDispatch';
import { BarMenu } from '../fullMenu';

export const CustomerTable = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { tables } = useSelector<IStoreState, ITableViewDataState>(
    state => state.tablesView
  );

  useEffect(() => {
    const tableId = Number(id);
    dispatch(tableViewDataAsync({id:tableId}));
  }, [dispatch, id]);

  return (
    <div>
      {tables?.success && (
        <BarMenu productsAndCategory={tables.productsWithCategory} orders={tables?.tables?.[0]?.orders ?? []} isAllowedToEditOrder={tables.isAllowedToEditOrder} />
      )}
    </div>
  );
};
