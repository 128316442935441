import { IStoreState } from '../../store';
import {
    ITableViewDataState,
} from '../../store/slices/tableSlice';
import { useSelector } from 'react-redux';
import { BarMenu } from '../fullMenu';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';


export const WaiterTable = () => {

    const navigate = useNavigate();
    const location = useLocation() as { state: { id?: number } };

    const { tables } = useSelector<IStoreState, ITableViewDataState>(
        state => state.tablesView
    );

    const selectedTable = useMemo(() => {
        if (location.state?.id) {
            return tables?.tables?.find(table => table.id === location.state?.id)
        }
    }, [tables,location.state?.id])

    const onBackClick = () => {
        navigate('/', { state: { isBack: true } })
    }


    return (
        <div>
            <button className='back-button' onClick={onBackClick}> Back</button>
            {tables?.success && (
                <BarMenu productsAndCategory={tables.productsWithCategory} orders={selectedTable?.orders} isAllowedToEditOrder isCustomer />
            )}
        </div>
    );
};
