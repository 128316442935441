import { configureStore } from '@reduxjs/toolkit';
import { loginReducer, orderReducer, tableViewReducer,loadingReducer } from './slices';

export const store = configureStore({
  reducer: {
    tablesView: tableViewReducer,
    login: loginReducer,
    order: orderReducer,
    loading:loadingReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
