import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from './loadingSlice';
import { api } from '../../config';

interface Notification {
    notificationId: number;
    tavId: number;
    notificationType: 'askToPay' | 'askForWaiter';
    status: 'Created' | 'Read';
    createdTime: string;
    waiterId?: number | null;
    updatedAt?: string | null;
}

export interface INotificationState {
    notifications: Notification[];
}

const initialState: INotificationState = {
    notifications: [],
};

export const createNotificationAsync = createAsyncThunk(
    'notifications/create',
    async ({ tavId, notificationType }: {
        tavId: number, notificationType: 'askToPay' | 'askForWaiter'
    }, thunkAPI) => {
        try {
            thunkAPI.dispatch(startLoading());
            const response = await api.post('api/notifications', { tavId, notificationType });
            return await response.data;

        } catch (error) {
        } finally {
            thunkAPI.dispatch(stopLoading());

        }
    }
);

export const readNotificationAsync = createAsyncThunk(
    'notifications/read',
    async ({ notificationId , tavId}: {
        notificationId: number,tavId:number
    }, thunkAPI) => {
        try {
            thunkAPI.dispatch(startLoading());
            const response = await api.put(`api/notifications/${notificationId}/read`,{tavId});
            return await response.data;

        } catch (error) {
        } finally {
            thunkAPI.dispatch(stopLoading());
        }
    }
);


const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {

        clearNotifications: (state) => {
            state.notifications = [];
        },
    },

    extraReducers: (builder) => {
        builder.addCase(createNotificationAsync.fulfilled, (state, action) => {
            state.notifications = action.payload;
        })
    },
});

export const { clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
