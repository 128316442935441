import React from 'react';
import './loadingspinner.css'; 
import { useSelector } from 'react-redux';
import { IStoreState } from '../../store';

export const LoadingSpinner = () => {
  const  isLoading  = useSelector<IStoreState, boolean>(
    state => state.loading.isLoading
  );

  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

