import { UserRole } from "../../../store";
import { INotification } from "./INotification";

export interface IMyReport{
  dailyEarn?: string, dailyOrder?: number 
}

export interface ITableResponse {
  success?: boolean;
  tables?: ITable[];
  role?: UserRole;
  isAllowedToEditOrder?: boolean;
  productsWithCategory?: IProductsWithCategory[];
  myReport?: IMyReport
}

export interface IProductsWithCategory {
  category?: string;
  products?: IProduct[];
}

export interface IProduct {
  id?: number;
  name?: string;
  quantity?: number;
  price?: number;
}

export interface ITable {
  id?: number;
  name?: string;
  positionx?: number;
  positiony?: number;
  chair?: string;
  date?: string;
  availability?: string;
  orders?: IOrder[];
  notifications?: INotification[]
}

export enum ProductActionEnum {
  increase = "increase",
  decrease = 'decrease',
  delete = 'delete'
}

export interface IProductAction {
  productId: number;
  action: ProductActionEnum;
}

export interface IOrderRow {
  orderId: number;
  tavId: number;
  product_id: number;
  quantity: number;
  price: string;
  productName: string;
}

export interface IOrderProduct {
  productId: number;
  quantity: number;
  price: number;
  name: string;
}

export enum OrderStatusEnum {
  Pending = 'pending',
  Completed = 'completed',
  Canceled = 'canceled',
  InProgress = 'in_progress',
  Deleted = 'deleted'
}


export interface IOrder {
  orderId: number;
  tavId: number;
  products: IOrderProduct[];
  orderStatus?: OrderStatusEnum;
  created: string;
}

export interface IOrderDetails {
  message: string;
  orderDetails: IOrderProduct[];
  tavId: number;
  totalCost: number;
  orders: IOrder[]
}


export interface IOrderUpdate {
  tavId: number;
  orders: IOrder[]
}

export interface INotificationUpdate {
  tavId: number;
  notifications?: INotification[]
}