import './layout.css';
import { Footer } from "../footer";
import { Header } from "../header/Header"


interface LayoutProps {
    children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
    return <div className="layout">
        <Header />
        <div className="main-content">
            {children}
        </div>
        <Footer />
    </div>
}