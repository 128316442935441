import { modifyProductOnCart } from '../../../store/slices/tableSlice';
import { ProductActionEnum } from '../../table';
import { useAppDispatch } from '../../table/hooks/useAppDispatch';
import './qtyform.css';

export const QuantityForm = ({ quantity,productId }: { quantity: number ,productId:number}) => {
    const dispatch  = useAppDispatch()
    return (
        <div>
            <div className="qty-item text-center">
                <span
                    className="dec d-flex justify-content-center align-items-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="minus"
                    data-bs-original-title="minus"
                    onClick={()=>dispatch(modifyProductOnCart({productId, action:ProductActionEnum.decrease}))}
                >
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-minus-circle feather-14"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                    </svg>
                </span>

                <input
                    type="text"
                    disabled
                    className="form-control text-center"
                    name="qty"
                    value={quantity}
                />

                <span
                    className="inc d-flex justify-content-center align-items-center"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    aria-label="plus"
                    data-bs-original-title="plus"
                    onClick={()=>dispatch(modifyProductOnCart({productId, action:ProductActionEnum.increase}))}

                >
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-plus-circle feather-14"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="16" />
                        <line x1="8" y1="12" x2="16" y2="12" />
                    </svg>
                </span>
            </div>
        </div>
    )
}