import { useNavigate } from 'react-router';

export const Report = () => {
  const navigate = useNavigate();
  return (
    <div>
      Report
      <h1 onClick={() => navigate('/dashbaord')}>Test</h1>
    </div>
  );
};
