

import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import {  IOrderDetails, } from '../models';
import { updateOrders } from '../../../store/slices/tableSlice';
import { useAppDispatch } from './useAppDispatch';


export const useOrderUpdate = (tavId: number) => {

  const dispatch = useAppDispatch();
  const [notifications, setNotifications] = useState<string[]>([]);

  useEffect(() => {
    const socket = io('http://localhost:8080');

    socket.on(`order-update-${tavId}`, (data: IOrderDetails) => {
      dispatch(updateOrders(data))
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        data.message,
      ]);
    });

    return () => {
      socket.disconnect();
    };
  }, [tavId,dispatch]);

  return {  notifications };
};

