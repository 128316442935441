import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [data,setData] = useState<any>()
  const [error,setError] = useState<any>()

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const res = await fetch('http://localhost:8080/api/table/find/2');
        const json = await res.json();
        setData(json);
      } catch (error) {
        setError(error)
        console.error('Error fetching data:', error);
      
      }

    };

    fetchData();
  },[])

  return <div>
    <div onClick={() => navigate('/reports')}>Dashboard</div>
    <p>{data?.tables[0].availability}</p>
    <p>{error?.message}</p>
    </div>
};
