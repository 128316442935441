import { AllCategory } from "../Icons"
import { IRenderIcons } from "../model"

interface IRenderIconProps {
    iconName: string;
}

export const RenderIcon = ({ iconName }: IRenderIconProps) => {

    const renderIcons: IRenderIcons = {
        all: <AllCategory />
    }
    return renderIcons[iconName]

}