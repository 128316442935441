import '../slider.css';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import { ICustomSliderProps } from '../model';
import { RenderIcon } from './RenderIcon';

export const CategorySlider = ({
  setActiveCategory,
  settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    afterChange: (index) => {
      setActiveCategory(index);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  },
  sliderTitle,
  categoryList
}: ICustomSliderProps) => {
  const sliderRef = useRef<Slider>(null);

  const goNext = () => {
    sliderRef.current?.slickNext();
  };

  const goPrev = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <div className="slider-container">
      {sliderTitle && <div>{sliderTitle}</div>}
      <Slider className='category-menu' ref={sliderRef} {...settings}>
        {categoryList?.map(cat =>
        (
          <div key={cat.name}>
            <div>
              <RenderIcon key={cat.name} iconName={cat.name ?? ''} />
            </div>
            <div>
              {cat.name}
            </div>
            <div>
              {cat.productsQty}
            </div>
          </div>
        )
        )}
      </Slider>
      <div className="arrow-container">
        <button className="custom-arrow prev" onClick={goPrev}>
          {'<'}
        </button>
        <button className="custom-arrow next" onClick={goNext}>
          {">"}
        </button>
      </div>
    </div>
  );
};

