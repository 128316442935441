import { useRef, useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router';
import { ILoginState, loginAsync } from '../../store/slices/loginSlice';
import { useAppDispatch } from '../table/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../store';

export const Login = () => {

  const login = useSelector<IStoreState, ILoginState>(state => state.login);


  const [animation, setAnimation] = useState(1);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleEmailFocus = () => {
    setAnimation(3);
  };

  const handlePasswordFocus = () => {
    setAnimation(2);
  };

  const handleBlur = () => {
    setAnimation(1);
  };


  const onLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const password = passwordRef.current?.value;
    const name = nameRef.current?.value;
    if (name && password) {
      dispatch(loginAsync({ name, password }))
        .unwrap()
        .then(data => {
          if (data.success) {
            localStorage.setItem('token', data.token!);
            navigate('/');
          }
        });
    }
  };

  return (
    <div className='login-page'>
      <div className='container'>
        <div className='card'>

      <p>{login?.error?.message}</p>
      <p>{login?.error?.code}</p>
          <div className='cartoon'>
            {animation === 1 && (
              <img src='https://i.ibb.co/98gpLCQ/l1.png' alt='' />
            )}
            {animation === 2 && (
              <img src='https://i.ibb.co/Vq5j4Vg/l2.png' alt='' />
            )}
            {animation === 3 && (
              <img src='https://i.ibb.co/Y0jsj90/l3.png' alt='' />
            )}
          </div>
          <form onSubmit={onLoginSubmit}>
            <div className='input-group'>
              <input
                ref={nameRef}
                type='text'
                id='email'
                name='name'
                placeholder='Emri'
                required
                onFocus={handleEmailFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className='input-group'>
              <input
                ref={passwordRef}
                type='password'
                id='password'
                name='password'
                placeholder='Passwordi'
                required
                onFocus={handlePasswordFocus}
                onBlur={handleBlur}
              />
            </div>
            <button type='submit'>Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};
