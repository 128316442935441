import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../config';
import { IOrder, IOrderCreationRQ, OrderStatusEnum } from '../../components';
import { startLoading, stopLoading } from './loadingSlice';


interface IOrderCreationRS {
  message?: string;
  success?: boolean;
  orders?: IOrder[]
}

export interface IOrderState {
  order: IOrderCreationRS;
}

const initialState: IOrderState = {
  order: {}
};

export const orderCreateAsync = createAsyncThunk(
  'order/create',
  async (orderData: IOrderCreationRQ, thunkAPI) => {

    try {
      thunkAPI.dispatch(startLoading());
      const response = await api.post('api/order/create', orderData);
      return await response.data;

    } catch (e) {

    } finally {
      thunkAPI.dispatch(stopLoading());
    }
  }
);

export const orderRejectAsync = createAsyncThunk(
  'order/reject',
  async ({ id, tavId }: { id: number, tavId: number }, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const response = await api.put('api/order/reject', { id, tavId });
      return await response.data;

    } catch (e) {
    } finally {
      thunkAPI.dispatch(stopLoading());
    }
  }
);


export const updateOrderStatusAsync = createAsyncThunk('order/status-update', async ({ id, status, tavId }: { id: number, status: OrderStatusEnum, tavId: number }, thunkAPI) => {

  try {

    thunkAPI.dispatch(startLoading());
    const response = await api.put(`api/order/update-status/${id}`, {
      status, tavId
    });
    return await response.data;

  } catch (e) {

  } finally {
    thunkAPI.dispatch(stopLoading());

  }


});

export const orderCreationSlice = createSlice({
  name: 'create/order',
  initialState,
  reducers: {
  },

  extraReducers: builder => {
    builder.addCase(
      orderCreateAsync.fulfilled,
      (state: IOrderState, action) => {
        state.order = action.payload;
      }
    );

  },
});

export default orderCreationSlice.reducer;
