import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from '../../config';
import { startLoading, stopLoading } from './loadingSlice';

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  Waiter = 'waiter',
}

export interface ILoginState {
  success: boolean;
  token?: string;
  name?: string;
  menu?: string[];
  role?: UserRole;
  error?:any;
}

const initialState: ILoginState = {
  success: false,
};

interface ILoginPayload {
  name?: string;
  password?: string;
}

export const loginAsync = createAsyncThunk<ILoginState, ILoginPayload>(
  'login/loginAsync',
  async ({ name, password },thunkAPI) => {

    try{
      thunkAPI.dispatch(startLoading());
      const response = await api.post('/api/user/login', { name, password });
      return response.data;
    }catch(e){
      thunkAPI.dispatch(setError(e))
      console.log(e,'errori!!')
    }finally{
      thunkAPI.dispatch(stopLoading());

    }

  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setError:(state,action)=>{
      state.error = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      loginAsync.fulfilled,
      (state, action: PayloadAction<ILoginState>) => {
        const { menu, success, token, name, role } = action.payload;
        state.menu = menu;
        state.success = success;
        state.token = token;
        state.name = name;
        state.role = role;
      }
    );
    builder.addCase(loginAsync.rejected, state => {
      state.success = false;
      state.token = undefined;
      state.menu = [];
      state.name = undefined;
      state.role = undefined;
    });
  },
});


export const { setError } = loginSlice.actions;

export default loginSlice.reducer;
