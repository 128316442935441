import { useCallback,  useState } from "react";
import { useAppDispatch } from "../../table/hooks/useAppDispatch";
import { IProduct, IProductsWithCategory } from "../../table";
import { addProductToCart, ITableViewDataState } from "../../../store/slices/tableSlice";
import { useSelector } from "react-redux";
import { IStoreState } from "../../../store";


export const useMenu = () => {
    const { recipt } = useSelector<IStoreState, ITableViewDataState>(
        state => state.tablesView
    )
    const [activeCategory, setActiveCategory] = useState<number>(0);
    const dispatch = useAppDispatch();

    const categoryList = useCallback((productsAndCategory?: Readonly<IProductsWithCategory[]>) => {
        return productsAndCategory?.map(prod => {
            return {
                name: prod.category,
                productsQty: prod.products?.length ?? 0,
            }
        })
    }, [])

    const onAddToCart = (product: IProduct,isAllowedToEditOrder?:boolean) => {
        if(!isAllowedToEditOrder) return;
        const {name,id,price,quantity} = product;
        if(quantity === 0) return;
        const reciptProduct= {
            name,
            id,
            price,
            quantity: 1,
        }
        dispatch(addProductToCart(reciptProduct))
    }

    return { activeCategory, setActiveCategory, categoryList, onAddToCart,recipt }
}