import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Layout } from '../components/layout';

interface ProtectedRouteProps {
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const token = localStorage.getItem('token');
  let isAuthenticated = false;
  if (token) {
    try {
      const { exp } = jwtDecode<{ exp: number }>(token);
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem('token');
        isAuthenticated = false;
      } else {
        isAuthenticated = true;
      }
    } catch (error) {
      localStorage.removeItem('token');
    }
  }

  return isAuthenticated ? <Layout> {element} </Layout> : <Navigate to='/login' />;
};

export default ProtectedRoute;
