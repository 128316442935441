import { CategorySlider } from "../../slider"
import { ICategoryList } from "../../slider/model";
import { IProduct, IProductsWithCategory } from "../../table";

export interface IProductListProps {
    setActiveCategory: React.Dispatch<React.SetStateAction<number>>;
    listOfCategory?: ICategoryList[];
    onAddToCart: (product: IProduct, isAllowedToEditOrder?: boolean) => void
    isAllowedToEditOrder?: boolean;
    activeCategory: number;
    productsAndCategory?: Readonly<IProductsWithCategory[]>;


}

export const ProductList = ({ isAllowedToEditOrder, listOfCategory, onAddToCart, productsAndCategory, setActiveCategory, activeCategory }: IProductListProps) => {
    return <div className='product-list' >
        <CategorySlider setActiveCategory={setActiveCategory} categoryList={listOfCategory ?? []} />
        <div className='product-container'>
            {productsAndCategory?.[activeCategory].products?.map(product => (
                <div key={product.id} onClick={() => onAddToCart(product, isAllowedToEditOrder)} className={`single-product ${product.quantity === 0 ? 'disabled' : ''}`}>
                    <p className='text-center'>
                        {product.name}
                    </p>
                    <div className='product-qty'>
                        <p className='product-price'>{product.quantity === 0 ? 'Ska gjendje' : `${product.quantity} cope`}  </p>
                        <p className='product-price'> <span>{product.price} L </span>
                        </p>

                    </div>
                </div>
            ))}
        </div>
    </div>
}