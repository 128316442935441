import axios from 'axios';
import { showToast } from '../utils/toastifyUtils';


export const api = axios.create({
  baseURL: 'https://mywaiter.onrender.com',
  withCredentials:true,

});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Content-Type'] = 'application/json';

  return config;
});

api.interceptors.response.use(
  response => {
    response.data.success ? showToast.success(response?.data?.message) : showToast.error(response?.data?.message);
    return response
  },
  error => {
    showToast.error(error.response?.data?.message)
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
