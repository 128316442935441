import '../barmenu.css';
import { IOrder, IProduct, OrderStatusEnum } from '../../table';
import { QuantityForm } from './QuantityForm';
import { useRecipt } from '../hooks';
import { MdDelete } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { Accordion } from 'react-bootstrap';
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { FaMoneyBill } from "react-icons/fa";
import { GrInProgress } from "react-icons/gr";
import { UserRole } from '../../../store';
import { CancelOrderButton } from './CancelOrder';

interface IReciptsProps {
  recipt: IProduct[];
  orders?: IOrder[];
  isAllowedToEditOrder?: boolean;
  role?: UserRole
  isCustomer?: boolean;
}


export const Recipts = ({ recipt, orders, isAllowedToEditOrder, role, isCustomer }: IReciptsProps) => {

  const { calculateTotalAmount, onDeleteItemFromCart, onOrderCreation, onUpdateOrder, handlePrint, onOrderReject, onAskForWaiter } = useRecipt();

  return <div className='recipts-and-order'>
    {orders && orders.length > 0 && isAllowedToEditOrder && <Accordion defaultActiveKey="0" className='active-order'>
      {orders.map(order => (
        <Accordion.Item className={order.orderStatus} eventKey={order.orderId.toString()} key={order.orderId}>
          <Accordion.Header>Porosia #{order.orderId}  </Accordion.Header>
          <Accordion.Body>
            <div>
              <p className='order-time'>Ora {order.created.split(' ')[1]}</p>
              {order.products.map(product => (
                <div className='recipt-order-product' key={product.productId}>
                  <p>
                    {product.quantity} x {product.name}
                  </p>
                  <p>
                    {Number(product.quantity) * Number(product.price)} L
                  </p>
                </div>
              ))}
              <hr />
              <div className='order-footer'>
                <div className="total">
                  <p>Total</p>
                  <p>
                    <strong>{calculateTotalAmount(order.products)} L</strong>
                  </p>
                </div>
              </div>

              <div className="action-order-button">
                <button onClick={() => handlePrint(order.orderId, order.products)}><MdOutlineLocalPrintshop size={16} /> Printo</button>
                <CancelOrderButton onOrderReject={() => onOrderReject(order.orderId)} orderStatus={order.orderStatus} orderId={order.orderId} role={role} />
                <button
                  className="paguaj"
                  onClick={() =>
                    onUpdateOrder(
                      order.orderId,
                      order.orderStatus === OrderStatusEnum.InProgress
                        ? OrderStatusEnum.Completed
                        : OrderStatusEnum.InProgress
                    )
                  }
                >
                  {order.orderStatus === OrderStatusEnum.InProgress ? (
                    <>
                      <FaMoneyBill size={16} /> Paguaj
                    </>
                  ) : (
                    <>
                      <GrInProgress size={16} /> Gati
                    </>
                  )}
                </button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>}
    <div className='recipts'>
      <div className='recipt-header'>
        <h3>Fatura</h3>
        {recipt.length > 0 && (
          <button className='primary' onClick={() => onOrderCreation(recipt)}>
            Porosit
            <FcApproval size={26} />
          </button>
        )}

      </div>
      <div className="recipt-item-container">
        {recipt.map(rec => (
          <div className='recipt-item' key={rec.id}>
            <div>
              <p>{rec.name}</p>
              <p className='product-price'>{rec.price} L</p>
            </div>
            {rec.quantity && <QuantityForm key={rec.id} quantity={rec.quantity} productId={Number(rec.id)} />}
            <div className='delete-icon'>
              <span onClick={() => onDeleteItemFromCart(Number(rec.id))}>
                <MdDelete color='red' />
              </span>
            </div>
          </div>
        ))}
      </div>

      {recipt.length > 0 && <div className='total-recipt'>Total <span>{calculateTotalAmount(recipt)} L</span></div>}
    </div>
    <div />
    {!isCustomer && (
      <div className="user-action-buttons">
        <button onClick={() => onAskForWaiter('askToPay')}>
          Dua Te Paguaj
        </button>
        <button onClick={() => onAskForWaiter('askForWaiter')}>
          Kerko Kamarier
        </button>
      </div>
    )}
  </div>
};
