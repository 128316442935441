import './barmenu.css';
import { IOrder, IProductsWithCategory } from '../table/models';
import { Recipts } from './components';
import { useMenu } from './hooks';
import { UserRole } from '../../store';
import { ToggleTabs } from '../__common__/';
import { useMemo, useState } from 'react';
import { ProductList } from './components/ProductList';
import { useScreenSize } from '../../hooks';
import { tablet } from '../../constants';

interface IBarMenuProps {
  productsAndCategory?: Readonly<IProductsWithCategory[]>;
  orders?: IOrder[];
  isAllowedToEditOrder?: boolean;
  role?: UserRole;
  isCustomer?: boolean;
}

const tabs = [{ label: 'Menu', value: 'menu' }, { label: 'Fatura', value: 'fatura' }];

export const BarMenu = ({ productsAndCategory, orders, isAllowedToEditOrder, role, isCustomer }: IBarMenuProps) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  const { activeCategory, categoryList, onAddToCart, setActiveCategory, recipt } = useMenu();

  const { width} = useScreenSize()

  const listOfCategory = categoryList(productsAndCategory ?? [])
  const isTabletOrMobile = useMemo(()=> width <= tablet,[width]);
  const isDesktopVersion = useMemo(()=> width> tablet,[width])
  const shouldShowProductList = useMemo(()=>selectedTab === tabs[0].value,[selectedTab]);
  const shouldShowRecipts = useMemo(()=>selectedTab === tabs[1].value,[selectedTab]);

  return (
    <>
    
      {isTabletOrMobile && <ToggleTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} />}

      <div className="product-recipt">
       
        {isTabletOrMobile && (
          <>
            {shouldShowProductList && (
              <ProductList
                activeCategory={activeCategory}
                isAllowedToEditOrder={isAllowedToEditOrder}
                listOfCategory={listOfCategory}
                onAddToCart={onAddToCart}
                setActiveCategory={setActiveCategory}
                productsAndCategory={productsAndCategory}
              />
            )}

            {shouldShowRecipts && (
              <Recipts
                isCustomer={isCustomer}
                recipt={recipt}
                orders={orders}
                isAllowedToEditOrder={isAllowedToEditOrder}
                role={role}
              />
            )}
          </>
        )}

        {isDesktopVersion && (
          <>
            <ProductList
              activeCategory={activeCategory}
              isAllowedToEditOrder={isAllowedToEditOrder}
              listOfCategory={listOfCategory}
              onAddToCart={onAddToCart}
              setActiveCategory={setActiveCategory}
              productsAndCategory={productsAndCategory}
            />

            <Recipts
              isCustomer={isCustomer}
              recipt={recipt}
              orders={orders}
              isAllowedToEditOrder={isAllowedToEditOrder}
              role={role}
            />
          </>
        )}
      </div>
    </>
  );
};
