import './table.css';
import { useEffect } from 'react';
import { SingleTable } from './SingleTable';
import {  useSelector } from 'react-redux';
import { IStoreState, tableViewDataAsync, ITableViewDataState, UserRole } from '../../store';
import { useAppDispatch } from './hooks/useAppDispatch';

export const Table = () => {
  const { tables } = useSelector<IStoreState, ITableViewDataState>(
    state => state.tablesView
  );

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (Object.keys(tables ?? {}).length > 0) return;
    dispatch(tableViewDataAsync({id:undefined}));
  }, [dispatch, tables]);

  const isAllowedToDrag = tables?.role === UserRole.Admin || tables?.role === UserRole.Manager ;

  return (
    <>
      {tables?.success &&
        tables.tables?.map(tab => (
          <SingleTable table={tab} key={tab.id} isAllowedToDrag={isAllowedToDrag} />
        ))}
    </>
  );
};
