import { useLocation, useParams } from 'react-router-dom';
import { orderCreateAsync, orderRejectAsync, updateOrderStatusAsync } from "../../../store/slices/orderSlice";
import { clearCart, modifyProductOnCart, updateOrders } from "../../../store/slices/tableSlice";
import { IProduct, OrderStatusEnum, ProductActionEnum } from "../../table"
import { useAppDispatch } from "../../table/hooks/useAppDispatch";
import { IOrderCreationRQ } from "../model/IOrder";
import { useCallback, useMemo } from 'react';
import { createNotificationAsync } from '../../../store';

export const useRecipt = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams();
  const location = useLocation() as { state: { id?: number } };

  const calculateTotalAmount = (recipt: IProduct[]) => {
    return recipt.reduce((total, item) => {
      return total + (Number(item.price) * Number(item.quantity));
    }, 0);
  }

  const onDeleteItemFromCart = (productId: number) => {
    dispatch(modifyProductOnCart({ productId, action: ProductActionEnum.delete }))
  }

  const tavId = useMemo(() => id ? Number(id) : Number(location.state?.id), [id, location.state?.id])

  const onOrderCreation = (recipt: IProduct[]) => {
    const order: IOrderCreationRQ = {
      items: recipt,
      tavId
    }

    dispatch(orderCreateAsync(order)).unwrap().then(response => {
      dispatch(clearCart())
      if (response.success) {
        handlePrint(response?.orders?.[0]?.orderId, response?.orders?.[0]?.products);
        dispatch(updateOrders({
          tavId,
          orders: response?.orders ?? []
        }))
      }
    })
  }

  const onUpdateOrder = useCallback((id: number, status: OrderStatusEnum, isAllowedToEditOrder?: boolean) => {
    dispatch(updateOrderStatusAsync({ id, status, tavId })).unwrap().then(response => {
      if (response.success) {
        dispatch(updateOrders({
          tavId,
          orders: response?.orders
        }))
      }
    });
  }, [dispatch, tavId])

  const handlePrint = (id?: number, recipt?: IProduct[]) => {
    const printContent = `
          <html>
            <head>
              <title>Porosia ${id ?? ''}</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
                }
                .recipt-order-product {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
                }
                .order-footer {
                  margin-top: 20px;
                  font-size: 16px;
                }
                .total {
                  display: flex;
                  justify-content: space-between;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
          
              <div>
              <p></p>
                ${recipt?.map(
      (product) => `
                  <div class="recipt-order-product">
                    <p>${product.quantity} x ${product.name}</p>
                    <p>${Number(product.quantity) * Number(product.price)} Lek</p>
                  </div>`
    )
        .join('')}
                <hr />
                <div class="order-footer">
                  <p class="total">
                    <span>Total</span>
                    <span><strong>${calculateTotalAmount(recipt ?? [])} Lek</strong></span>
                  </p>
                </div>
              </div>
            </body>
          </html>
        `;

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  const onOrderReject = useCallback((id: number)=>{
    dispatch(orderRejectAsync({id,tavId})).unwrap().then(response =>{
      if(response.success){
        dispatch(updateOrders({
          tavId,
          orders: response?.orders
        }))

      }
    })
  },[dispatch,tavId])

  const onAskForWaiter = useCallback((notificationType: "askToPay" | "askForWaiter")=>{
    dispatch(createNotificationAsync({tavId,notificationType}))
  },[dispatch,tavId])


  return {
    calculateTotalAmount, onDeleteItemFromCart, onOrderCreation, onUpdateOrder,handlePrint,onOrderReject,onAskForWaiter
  }
}