import { useState } from 'react';
import { ImCancelCircle } from 'react-icons/im'; 
import { UserRole } from '../../../store';
import { OrderStatusEnum } from '../../table';

interface ICancelOrderButtonProps{
     orderStatus?: OrderStatusEnum;
     role?: UserRole;
     orderId:number;
    onOrderReject: (orderId: number) => void;
}

export const CancelOrderButton = ({ orderStatus, role, onOrderReject, orderId }:ICancelOrderButtonProps ) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancelClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = () => {
    setIsModalOpen(false);
    onOrderReject(orderId)
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  return (
    <>
      {(role === UserRole.Admin && orderStatus === OrderStatusEnum.InProgress) || 
       (role !== UserRole.Admin && orderStatus ===  OrderStatusEnum.Pending) ? (
        <button className='danger-button' onClick={handleCancelClick}>
          <ImCancelCircle size={16} /> Anullo
        </button>
      ) : null}

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <h3>A je i sigurte qe do ta anullosh porosine?</h3>
            <div className='modal-actions'>
            <button onClick={handleConfirmCancel}>Po, Anulloje</button>
            <button className='back-button' onClick={handleCloseModal}>Jo, Kthehu pas</button>

            </div>
          </div>
        </div>
       )} 
    </>
  );
};
