import { Route, Routes } from 'react-router';
import App from '../App';
import ProtectedRoute from '../utils/ProtectedRoute';
import { NotFound } from '../components/NotFound';
import { CustomerTable, WaiterTable,Settings,Dashboard, Report, Login,MyProfile  } from '../components';


export const Navigation = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/tavolina/:id' element={<CustomerTable />} />
      
      <Route path='/' element={<ProtectedRoute element={<App />} />} />
      <Route path='/tavolina' element={<ProtectedRoute element={<WaiterTable />} />} />
      <Route path='/settings' element={<ProtectedRoute element={<Settings />} />} />
      <Route path='/profili' element={<ProtectedRoute element={<MyProfile />} />} />
      <Route
        path='/reports'
        element={<ProtectedRoute element={<Report />} />}
      />
      <Route
        path='/dashboard'
        element={<Dashboard />}
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};
