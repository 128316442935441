import { useCallback } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { ISingleTable } from '../models/ISingleTable';
import { tableUpdateAsync } from '../../../store/slices/tableSlice';
import { IOrder, OrderStatusEnum } from '../models';
import { useAppDispatch } from './useAppDispatch';
import { readNotificationAsync } from '../../../store';

export const useSingleTable = ({ table }: ISingleTable) => {
  const dispatch = useAppDispatch();

  const createArrayBasedOnNumber = (number: number): number[] => {
    let arr = [];
    for (let i = 1; i <= number; i++) {
      arr.push(i);
    }
    return arr;
  };

  const handleDrag = (
    e: DraggableEvent,
    data: DraggableData,
    id: number
  ): void => {
    if (data.x === table.positionx && data.y === table.positiony) {
      return;
    }
    dispatch(
      tableUpdateAsync({
        tableId: id,
        positionX: data.x,
        positionY: data.y,
      })
    );
  };


  const tableAviliability = useCallback((orders: IOrder[]) => {
    if (orders.length === 0) {
      return 'free';
    }
    if (orders.filter(order => order.orderStatus === OrderStatusEnum.Pending)) {
      return 'pending';
    }
    if (orders.length > 0) {
      return 'busy';
    }

  }, [])



  const onNotificationClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>, notificationId: number,tavId?:number) => {
    e.stopPropagation()
    dispatch(readNotificationAsync({ notificationId,tavId:Number(tavId) }))
  }, [dispatch])

  return { handleDrag, createArrayBasedOnNumber, tableAviliability, onNotificationClick };
};
