import { useEffect } from "react";
import { io } from 'socket.io-client';
import { basePath } from "../../../constants";
import { showToast } from "../../../utils/toastifyUtils";
import { useAppDispatch } from "./useAppDispatch";
import { updateNotfitication } from "../../../store";

export const useNotificationUpdate = (tavId: number) => {
const dispatch = useAppDispatch()

    useEffect(() => {
        const socket = io(basePath)

        socket.on(`notification-${tavId}`, (data) => {
            const message =  `Tavolina nr: ${tavId} kerkon ${data.message === 'askToPay' ? 'kerkon te paguaj' : 'nje kamarier'}`
            data.message &&  showToast.success(message)
           dispatch(updateNotfitication({tavId, notifications:data.notifications}))
        });

        return () => {
            socket.off(`notification-${tavId}`);
            socket.disconnect();
        };

    }, [tavId,dispatch]);

    
}